import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import { useNotify, hooks } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { stripeConnectVendor } from '../actions';

export const StripeConnectRouter = () => {
  // hooks
  const { useQuery } = hooks;
  const { code, scope, error } = useQuery();
  const Notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  // redux
  const vendor = useSelector(state => state.vendor);

  const onError = () => {
    Notify.error('There was an error connecting your stripe account. Please try again.');
    history.push({ pathname: '/jobs' });
  }

  useMount(() => {
    if(code && scope === 'read_write' && !error) {
      dispatch(stripeConnectVendor(vendor.id, { stripe_code: code })).then((result) => {
        Notify.default('Congrats, you are now connected to recieve payments automatically through Stripe!');
        history.push({ pathname: '/jobs' });
      }, onError);
    }
    else onError();
  });

  return <LoaderOverlay />;
};
