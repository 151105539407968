import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser, userService } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

export const SigninRedirect = () => {
  // hooks
  const { isAuthenticated, user } = useUser();
  const history = useHistory();

  // this screen is temp and only redirects based on authenticated status (jobs if signed in already, generic signin if not)
  useEffect(() => {
    if(!isAuthenticated) window.top.location = `${process.env.REACT_APP_CONSUMER_APP_URL}/signin`;
    else userService.redirect(user,history);
  // eslint-disable-next-line
  },[isAuthenticated]);

  return <LoaderOverlay/>;
};
