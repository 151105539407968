import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Moment from 'react-moment';
import * as Yup from 'yup';

import { saveSlot } from '../actions';

import { useNotify, format } from '@moved/services';
import { DynamicForm, AnimatedCheckmark } from '@moved/ui';
import avaCSS from '../styles/Availability.module.scss';

const slotValidation = Yup.object().shape({
  available: Yup.bool().required(),
  price: Yup.number().min(-1, 'You may not offer a discount of more than 100%').max(1, 'You may not increase the price more than 100%').required(),
  capacity: Yup.number().positive('Capacity must be a positive number').required()
});

export const SlotEdit = ({ slot, jobs, hide, closeBtn }) => {
  // hooks
  const dispatch = useDispatch();
  const Notify = useNotify();
  // state
  const [submitted, setSubmitted] = useState(false);
  // redux
  const pending = useSelector(state => state.requests.AVAILABILITY_SAVE_SLOT_PENDING);

  // helper functions
  const handleSubmit = (slotData) => {
    dispatch(saveSlot(slotData))
      .then(resp => {
        setTimeout(hide, 650);
        setSubmitted(true);
      })
      .catch((err) => Notify.error(format.error(err)));

  }

  const slotFields = [
    { type: 'hidden', name: 'date', value: slot.date },
    { type: 'hidden', name: 'position', value: slot.position },
    { label: 'Price adjustment', type: 'percentage', name: 'price', value: slot.price, maximum: 100 },
    { label: 'Additional trucks available', type: 'difference', name: 'capacity', value: slot.capacity, difference: jobs },
    { label: 'Accepting jobs', type: 'toggle', name: 'available', value: slot.available }
  ];

  return (
    <div className={avaCSS.slot_edit}>
      { submitted && (<div className={avaCSS.confirm}><AnimatedCheckmark isChecked={true} fast={true} /></div>) }
      <div className={avaCSS.slot_edit_title}>
        <span>{slot.label}</span>
        <span className={avaCSS.date}><Moment date={slot.date} format='MMM D YYYY'/>{closeBtn && (<span className={avaCSS.close} onClick={hide}>&times;</span>)}</span>
      </div>
      <div className={avaCSS.slot_edit_form}>
        <DynamicForm fields={slotFields} validation={slotValidation} formStyle={'stacked'} id="slot-form" onSubmit={handleSubmit} />
      </div>
      <div className={avaCSS.slot_edit_control}>
        <button
          className={'btn-primary flex-auto '+ (pending  ? 'loading' : '')}
          disabled={pending} form="slot-form" type="submit">
            Update
        </button>
      </div>
    </div>
  );
}
