import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { respondJobOffer } from '../actions';

export const DeclineJob = () => {
  // hooks
  const { token } = useParams();
  const Notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();

  // redux
  const pending = useSelector(state => state.requests.JOBS_RESPOND_JOB_OFFER_PENDING);
  const jobOffer = useSelector(state => state.jobOffers[token]);

  useEffect(() => {
    if(!jobOffer || pending) return;
    if(jobOffer.status !== 'pending' || moment.utc(jobOffer.expires_at).isBefore()) {
      return history.replace(`/confirm-job/${token}`);
    }
    dispatch(respondJobOffer(token,'decline',{}))
      .catch(error => Notify.error(format.error(error)));
  // eslint-disable-next-line
  },[token,jobOffer,pending]);

  return <LoaderOverlay/>;

};
