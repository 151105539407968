import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { hooks, useUser } from '@moved/services';

import bannerCSS from '../styles/StripeBanner.module.scss';

export const StripeBanner = (props) => {
  // hooks
  const isMobile = hooks.useMobile();
  const { isAuthenticated } = useUser();
  // redux
  const vendor = useSelector(state => state.vendor);
  // quick escape hatch
  if(!isAuthenticated || !vendor || vendor.has_stripe) return null;

  // build stripe link
  const {
    REACT_APP_STRIPE_CLIENT_ID: stripeClientId,
    REACT_APP_BASE_URL: appUrl,
  } = process.env;
  const stripeLink = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write` +
    `&client_id=${stripeClientId}&redirect_uri=${appUrl}/stripe_connect`;

  const buttonClasses = classNames('btn-primary',(isMobile ? 'btn--full' : 'btn--small'),bannerCSS.button);
  return (
    <div className={bannerCSS['stripe-banner']}>
      <div className="banner-content">
        { !isMobile && (
          <span>First, you&rsquo;ll need to set up a Stripe account.</span>
        )}
        <a className={buttonClasses} href={stripeLink}>Set Up Stripe</a>
      </div>
    </div>
  );
};
