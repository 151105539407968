import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { chunk, transform, isEmpty, cloneDeep } from 'lodash';
import moment from 'moment'
import Moment from 'react-moment';

import { hooks } from '@moved/services';
import { SlideToggle, MonthlyCalendar, LoaderOverlay } from '@moved/ui';

import { getAvailabilityCalendar, updateActiveDate } from '../actions';
import { LayoutStandard, HeaderSelect } from '../../common'
import { AvailabilityWeek, UpcomingJobs, CalendarFailure, CalendarPending, MobileAvailability } from '../'
import avaCSS from '../styles/Availability.module.scss';

// View options for selector
const options = [
  {label: "All", value: 'all'},
  {label: "Availability", value: 'availability'},
  {label: "Jobs", value: 'jobs'},
  {label: "Pricing", value: 'pricing'},
];

const weekOfMonth = (input = moment()) => {
  const firstDayOfMonth = input.clone().startOf('month');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
  const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
  return Math.ceil((input.date() + offset) / 7);
}

export const Availability = ({children}) => {
  // hooks
  const dispatch = useDispatch();
  const isMobile = hooks.useMobile();
  // redux
  const activeDate = useSelector(state => state.activeDate);
  const avail = useSelector(state => state.availability);
  const { schedules, preferences, vendor_jobs: jobs } = avail;
  const getCalendarPending = useSelector(state => state.requests.AVAILABILITY_GET_CALENDAR_PENDING);
  // state
  const [currentView, setCurrentView] = useState('all');
  const [error, setError] = useState(false);
  const date = activeDate ? moment(activeDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(getAvailabilityCalendar({date: date}))
      .catch(() => setError(true));
  // eslint-disable-next-line
  },[]);

  const selectDate = (date) => {
    dispatch(updateActiveDate(date.format('YYYY-MM-DD')));
    if(!moment(activeDate).isSame(date, 'month')) {
      setError(false);
      dispatch(getAvailabilityCalendar({date:date.format('YYYY-MM-DD')}))
        .catch(() => setError(true));
    }
  };

  const calArr = transform(cloneDeep(schedules), (result, value, key) => {
    value.date = key;
    result.push(value);
  }, []);
  const weeks = chunk(calArr, 7); //Chunk out the month into 7 day weeks
  const week = (weekOfMonth(moment(activeDate).startOf('day')) - 1);  //Get week of the month

  // Render calendar fetch states
  if(error) {
    return (
      <CalendarFailure />
    );
  } else if (isEmpty(schedules)) {
    return (
      <CalendarPending />
    );
  }
  return (
    <LayoutStandard
      leftHeaderContent={
        !isMobile && !getCalendarPending && (
          <>
            Week of&nbsp;<Moment date={weeks[week][0].date} format='MMM Do' /> &nbsp;&ndash;&nbsp; <Moment date={weeks[week][6].date} format='MMM Do YYYY' />
          </>
        )
      }
      rightHeaderContent={
        !isMobile ? (
          <SlideToggle active={currentView} options={options} callback={setCurrentView} />
        ) : (
          <HeaderSelect
            name='view'
            value={{label: currentView.charAt(0).toUpperCase() + currentView.slice(1), value: currentView}}
            onChange={(selection) => { return setCurrentView(selection.value); }}
            options={options}
          />
        )
      }
    >
      <Helmet>
        <title>Availability Calendar : Moved</title>
      </Helmet>

      { getCalendarPending && (<LoaderOverlay />)}

      {isMobile ? (
        <MobileAvailability
          view={currentView}
          selected={activeDate}
          week={weeks[week]}
          preferences={preferences}
          jobs={jobs}
          getCalendarPending={getCalendarPending}
          selectDate={selectDate}
        />
      ):(
        <>
        <div className={avaCSS.main_area}>
          <AvailabilityWeek
            view={currentView}
            selected={activeDate}
            week={weeks[week]}
            preferences={preferences}
            jobs={jobs}
            getCalendarPending={getCalendarPending}
          />
        </div>
        <div className={avaCSS.side_area}>

          <div className={avaCSS.month_calendar}>
            <MonthlyCalendar
              selected={activeDate}
              onSelect={selectDate}
              moment={moment(activeDate)}
              highlightWeek={true}
              highlightDay={false}
              hoverWeek={true}
            />
          </div>
          <UpcomingJobs jobDates={jobs} preferences={preferences} activeDate={activeDate} onSelect={selectDate} />

        </div>
        </>
      )}
      { children }
    </LayoutStandard>
  );
};
